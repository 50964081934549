<template>
  <div class="info-page">
    <van-nav-bar title="传感器数据" left-text="返回" left-arrow @click-left="onClickLeft"/>
    <div class="glo-content">
        <div v-if="loading">
            <van-loading type="spinner" vertical>加载中</van-loading>
        </div>
        <div v-else>
            <van-collapse v-model="activeNames" accordion>
                <van-collapse-item v-for="(item,index) in sData" :key="index" :title="item.devName || '暂无名称'" :name="index">
                    <van-cell title="设备id" :value="item.devCode" />
                    <van-cell title="设备类型" :value="item.devType == 'VIB'?'振动模块':'振动模块'" />
                    <van-cell title="设备时间" :value="item.devTime" />
                    <van-cell title="地表位移" :value="(item.vibamp / 1).toFixed(2) +' mm'" />
                    <van-cell title="倾斜角度" :value="item.xaxisangle +' °'" />
                    <van-cell title="电池电压" :value="(item.voltage*0.01 || 0) +' V'" />
                    <van-cell title="工作温度" :value="(item.chiptemp || 0) +' ℃'" />
                    <van-cell title="信号强度" :value="item.rssi || 0" />
                </van-collapse-item>
            </van-collapse>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
        activeNames:0,
        bridgeCode:'',
        loading:true,
        sData:[]
    }
  },
  methods:{
      onClickLeft(){
        this.$router.go(-1);//返回上一层
      },
      // 获取设备传感器信息
      getData(){
        //   this.$api.SLOPE.realTimeList({
        //       bridgeCode:this.bridgeCode
        //   }).then( d => {
        //       this.sData = d;
        //       this.loading = false;
        //   })
        this.$api.NEWBRIDGE.sensorDetail({
      
      projectCode:window.sessionStorage.getItem('projectCode')
        }).then(d=>{
            if(Array.isArray(d)){
                this.sData = d.map( v => ({
                    devName:v.devName,
                    devCode:v.devCode,
                    xaxisangle: v.cycleData && v.cycleData.curAnglez || 0,
                    vibamp: v.cycleData && v.cycleData.cycleVibrateDataData && v.cycleData.cycleVibrateDataData.vibAmp || 0,
                    devTime: v.cycleData && v.cycleData.uploadTime,
                    voltage: v.cycleData && v.cycleData.batVolt,
                    chiptemp: v.cycleData && v.cycleData.chipTemp,
                    rssi:v.cycleData && v.cycleData.rsrp,
                    status: v.devStatus,

                    top: (v.info && v.info.y || 0) +"%",
                    left:(v.info && v.info.x || 0) +"%",
                }))

                this.loading = false;
            }
    })
      }
  },
  mounted(){
      document.querySelector('body').setAttribute('style', 'background-color:#f0f0f0');
      this.bridgeCode = window.sessionStorage.getItem('bridgeCode');
      this.bridgeCode ? this.getData() : void 0;
  },
  beforeDestroy() {
      document.querySelector('body').removeAttribute('style')
}
}
</script>
<style lang="scss" scoped>
.van-cell__title{text-align: left;}
.van-cell__value {
    -webkit-box-flex: inherit;
    -webkit-flex: inherit;
    flex: inherit;
    display: inherit;
}
.van-loading--vertical {
    margin: 50% auto;
}
</style>